























































/**框架*/
import Vue from "vue"
/**工具库*/
import { signTxGroup } from "@33cn/wallet-api"
/**类型*/
/**组件*/
import { Toast } from "vant"
/**自定义方法*/
import {
  unbindPool as unbindPoolHandler,
  ticketClose,
  delPool,
  continueMining,
} from "@/service/pool"
import service from "@/service/chain33"
import { TICKET_ADDRESS } from "@/constance/const"

// const list = [
//   "1MfveuSypQGgcnE9aRYwaFfGkvDDp9yjnw",
//   "1CUwWH7Nwi7Z6VpEQ7sN4PkyoHjgoT8LNd",
//   "1FmqNCVbZHGk16qsug9WkJza64hnXmEyPf",
//   "13Y4FiALJW6mYqHLVQB1u4v5XJsAck4uNq",
//   "185kFAcBujtzFynmxj9YmvNZnReGcm9BS2",
//   "122hrJvhSiE7vuBD8WaQCjYmy5hdJC5MPm",
//   "1LHrTELv8Ruz8eyGzb5XJ41HNdZJAVU2KT",
//   "147khyKmtpFBoVVngKiNJHN3RyAA9UH9FS",
//   "1wdPLGkY5GJNQc5U5nqmGB3f8PoBEmAtY",
//   "174qfmE9MpSmtfacGpAbsLE1R4coP98eXr",
//   "1EpVBLvksSxfLf9vVyr6nYncjhFHbpWT4E",
//   "1DsppYB4KyGYidnPqpLwnkG1dAnK2ryw2J",
//   "1FCiya7ZmDbAhTWG35viPoFgnYSUHR1rHt",
// ]

export default Vue.extend({
  name: "",
  props: {
    poolBinding: {
      required: true,
    },
    poolUnBindingStep: {
      type: Number,
    },
  },
  data() {
    return {
      getBackProcess: this.poolUnBindingStep,
      ticket: {
        frozen: 0,
        balance: 0,
      },
      fee: 0,
      coolingLeft: 0,
      coolingText: "",
      coolingLeftInterval: 0,
      showContinue: true,
      // list,
    }
  },
  watch: {
    poolBinding: {
      handler(newValue) {
        /**继续挖矿按钮倒计时 */
        this.coolingLeft = (newValue as any).coolingLeft
        if (this.coolingLeftInterval) {
          clearInterval(this.coolingLeftInterval)
        }
        this.coolingLeftInterval = setInterval(() => {
          this.coolingLeft--
          this.coolingText = this.coolingHandler(this.coolingLeft)
          if (this.coolingLeft <= 0) {
            clearInterval(this.coolingLeftInterval)
          }
        }, 1000)
      },
      immediate: true,
    },
  },
  computed: {
    btnText(): string {
      switch (this.getBackProcess) {
        case 0:
          return "立即取回"
        case 1:
          return "解锁挖矿BTY"
        case 2:
          return "取回所有BTY"
        case 3:
          return "完成"
        default:
          return ""
      }
    },
  },
  async mounted() {
    await this.updateTicket()
    await this.getFee()
    /**初始的时候如果poolUnBindingStep为1则表示之前已经解绑了,那就再根据可取回的数量判断应该到哪一步 */
    if (this.poolUnBindingStep === 1 && this.ticket.frozen === 0) {
      if (this.ticket.balance <= 0) {
        this.getBackProcess = 3
      } else if (this.ticket.balance > 0) {
        this.getBackProcess = 2
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.coolingLeftInterval)
  },
  methods: {
    coolingHandler(coolingLeft: number): string {
      const d = Math.floor(coolingLeft / 3600 / 24)
      const h = Math.floor((this.coolingLeft % (3600 * 24)) / 3600)
      const m = Math.floor((coolingLeft % 3600) / 60)
      const s = Math.floor(coolingLeft % 60)
      return `${d}:${h}:${m}:${s}`
    },
    async getFee() {
      await service.getProperFee(0, 0).then((res) => {
        this.fee = res.data.result.properFee
      })
    },
    async updateTicket() {
      const res = await service.getBalance(
        this.$store.state.app.address,
        "ticket"
      )
      if (res) {
        this.ticket.frozen = res.data.result[0].frozen / 1e8
        this.ticket.balance = res.data.result[0].balance / 1e8
      }
    },
    setProcess(process: number) {
      this.getBackProcess = process
    },
    closePopup() {
      this.$emit("closePopup")
    },
    getBackProcessAction(poolBinding: any) {
      switch (this.getBackProcess) {
        case 0:
          this.unBindPool(poolBinding)
          break
        case 1:
          this.unFrozenTickets(poolBinding)
          break
        case 2:
          this.getBackAllTickets()
          break
        case 3:
          this.getBackComplete(poolBinding)
          break
      }
    },
    signGroup(txs: Array<string>) {
      return signTxGroup(txs.join(), "none", -1)
    },
    /**挖矿300天到期后，继续挖矿 */
    async continueMining(poolBinding: any) {
      this.$dialog
        .confirm({
          title: "保持委托",
          message: "确认是否保持委托？", //将为您开启自动购票功能，挖矿中您可以关闭自动购票功能
        })
        .then(async () => {
          let res = await continueMining({ addr: poolBinding.minerAddr })
          if (res.data.data.isSucc) {
            Toast("保持委托成功,即将刷新页面")
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          } else {
            Toast("保持失败")
          }
        })
        .catch((err) => {
          this.$toast(err.message || err)
        })
    },
    /**解绑票池 */
    async unBindPool(poolBinding: any) {
      this.$dialog
        .confirm({
          title: "取回BTY",
          message: "确认是否取回BTY？", //将为您开启自动购票功能，挖矿中您可以关闭自动购票功能
        })
        .then(async () => {
          /**后端解绑 */
          const unbindPoolRes = await unbindPoolHandler(poolBinding.id)
          this.showContinue = false
          /**区块链解绑 */
          const unbindPoolResChain33 = await service.createBindMiner({
            bindAddr: "",
            originAddr: this.$store.state.app.address,
            amount: 0,
            checkBalance: false,
          })
          /**构造签名 */
          const sign = await this.signGroup([
            unbindPoolResChain33.data.result.txHex,
          ])
          /**发送签名 */
          const send = await service.sendTransaction(sign)
          if (send.data.error || send.data.result.error) {
            Toast(send.data.error || send.data.result.error || "解绑失败")
            return
          }
          Toast.loading({
            duration: 0,
            message: "解绑中",
            forbidClick: true,
          })
          /**轮询查看交易是否成功 */
          const timer = setInterval(async () => {
            let res = await service.getTransaction(send.data.result)
            if (!res.data.error) {
              clearInterval(timer)
              const next = res.data.result.tx.next
              /**查看下一个交易是否成功 */
              const transaction = await service.getTransaction(next)
              if (transaction.data.result.receipt.ty === 1) {
                Toast(transaction.data.result.receipt.tyName || "解绑失败")
              } else if (transaction.data.result.receipt.ty === 2) {
                Toast("解绑成功")
                /**解绑成功后，如果没有冻结的票则直接到领取这一步，如果有则到解冻这步, 如果冻结和资产都没有则直接到最后一步 */
                if (this.ticket.frozen <= 0 && this.ticket.balance <= 0) {
                  this.getBackComplete(this.poolBinding)

                  // this.setProcess(3)
                } else if (this.ticket.frozen <= 0) {
                  this.setProcess(2)
                } else {
                  this.setProcess(1)
                }
              }
            }
          }, 1000)
        })
        .catch((err) => {
          this.$toast(err.message || err)
        })
    },
    /**解冻BTY */
    async unFrozenTickets(poolBinding: any) {
      this.$dialog
        .confirm({
          title: "解锁BTY",
          message: "确认是否解锁BTY？", //将为您开启自动购票功能，挖矿中您可以关闭自动购票功能
        })
        .then(async () => {
          const ticketCloseRes = await ticketClose({
            addr: this.$store.state.app.address,
            miner: poolBinding.minerAddr,
          })
          let send: any
          /**如果返回结果data存在则需要构造并发送签名 */
          if (ticketCloseRes.data.data) {
            /**构造签名 */
            const sign = await this.signGroup([ticketCloseRes.data.data])
            /**发送签名 */
            send = await service.sendTransaction(sign)
            if (send.data.error || send.data.result.error) {
              Toast(send.data.error || send.data.result.error || "解锁失败")
              return
            }
            /**发送后端进行关票操作 */
            Toast.loading({
              message: "解锁中",
              forbidClick: true,
              duration: 0,
            })
            /**轮询查看交易是否成功 */
            const timer = setInterval(async () => {
              let res = await service.getTransaction(send.data.result)
              if (!res.data.error) {
                clearInterval(timer)
                const next = res.data.result.tx.next
                //   /**查看下一个交易是否成功 */
                const transaction = await service.getTransaction(next)
                if (transaction.data.result.receipt.ty === 1) {
                  Toast("解锁失败")
                } else if (transaction.data.result.receipt.ty === 2) {
                  Toast("解锁成功")
                  await this.updateTicket()
                }
              }
              if (this.ticket.frozen <= 0) {
                this.setProcess(2)
              }
            }, 1000)
          } else {
            Toast("票未成熟，请过段时间再试")
          }
        })
        .catch((err) => {
          this.$toast(err.message || err)
        })

      //   /**每次关票后，更新一次ticket */
      //   await this.updateTicket();
      //   /**每次更新完后，如果剩余冻结的票为0则进行下一步 */
      //   if (ticketCloseRes.data.data) {
      //     Toast("解锁成功");
      //   } else {
      //     Toast(ticketCloseRes.data.message || '解锁失败');
      //   }
      //   if (this.ticket.frozen <= 0) {
      //     this.setProcess(2);
      //   }
    },
    /**领取BTY */
    async getBackAllTickets() {
      this.$dialog
        .confirm({
          title: "取回BTY",
          message: "确认是否取回BTY？", //将为您开启自动购票功能，挖矿中您可以关闭自动购票功能
        })
        .then(async () => {
          const transaction = await service.createTransaction({
            to: TICKET_ADDRESS,
            amount: this.ticket.balance * 1e8,
            fee: this.fee,
            note: "ticket -> coins",
            isWithdraw: true,
            execName: "ticket",
          })
          Toast.loading({
            message: "领取中",
            forbidClick: true,
            duration: 0,
          })
          service.signTransition(
            (transaction as any).data.result,
            async (sign: string | Object) => {
              const s = typeof sign === "string" ? JSON.parse(sign) : sign
              if (s.error) {
                Toast.fail(s.error)
                return
              }
              const send = await service.sendTransaction(s.signHash)
              if (send.data.error || send.data.result.error) {
                Toast(send.data.error || send.data.result.error)
                return
              }
              /**轮询查看交易是否成功 */
              const timer = setInterval(async () => {
                let res = await service.getTransaction(send.data.result)
                if (!res.data.error) {
                  clearInterval(timer)
                  if (res.data.result.receipt.ty === 1) {
                    Toast("领取失败")
                  } else {
                    Toast("领取成功")
                    await this.updateTicket()
                    /**领取成功后，更新票池如果冻结和余额都为0了则可以继续下一步 */
                    if (this.ticket.frozen + this.ticket.balance <= 0) {
                      this.getBackComplete(this.poolBinding)
                      // this.setProcess(3)
                    }
                  }
                }
              }, 1000)
            }
          )
        })
        .catch((err) => {
          this.$toast(err.message || err)
        })
    },
    /**完成领取 */
    async getBackComplete(poolBinding: any) {
      /**如果冻结的加上资产为0了，则通知后端解绑 */
      if (this.ticket.frozen + this.ticket.balance <= 0) {
        const res = await delPool(poolBinding.id)
        if (res.data.data.isSucc) {
          Toast("解绑成功,即将刷新页面")
          setTimeout(() => {
            window.location.reload()
          }, 1000)
          //   this.$emit("closePopup");
        } else {
          Toast(res.data.message || "解绑失败")
        }
      }
    },
  },
})
